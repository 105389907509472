import { useEffect, useState } from 'react'
/* eslint-disable */
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
  Box,
} from '@pancakeswap/uikit'
import Trans from 'components/Trans'
import useAuth from 'hooks/useAuth'
import CopyToClipboard from "react-copy-to-clipboard";
import { ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import { useRouter } from 'next/router'
import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
import WalletModal, { WalletView } from './WalletModal'
import ProfileUserMenuItem from './ProfileUserMenuItem'
import WalletUserMenuItem from './WalletUserMenuItem'

const UserMenu = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { account, error } = useWeb3React()
  const { logout } = useAuth()
  
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const { isInitialized, isLoading, profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  const hasProfile = isInitialized && !!profile
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }

  const UserMenuItems = () => {
    return (
      <>
        <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />
        <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
          {t('Recent Transactions')}
          {hasPendingTransactions && <RefreshIcon spin />}
        </UserMenuItem>
        <UserMenuDivider />
 
        <ProfileUserMenuItem isLoading={isLoading} hasProfile={hasProfile} disabled={isWrongNetwork} />
        <UserMenuDivider />
        <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {t('Disconnect')}
            <LogoutIcon />
          </Flex>
        </UserMenuItem>
      </>
    )
  }

  const DAI_ADDRESS = '0x86333e34c8804532ae34207c46e741cbc3ff3fe3';
  const DAI_SYMBOL = 'CRP';
  const DAI_DECIMALS = 18;
  const DAI_IMAGE = 'hhttps://miner.cryptopeso.co/crypto-pesos.png';

  async function connectMetaMask() {
      if (window.ethereum) {
          try {
              await window.ethereum.request({ method: 'eth_requestAccounts' });
          } catch (e) {
              console.error('El usuario rechazó la conexión', e);
          }
      } else {
          alert('Por favor instala MetaMask primero.');
      }
  }

  async function addToMetaMask() {
      try {
          if (!window.ethereum || !window.ethereum.isMetaMask) {
              alert('Por favor instala MetaMask primero.');
              return;
          }

          // Primero intenta conectar con MetaMask.
          await connectMetaMask();

          // Luego intenta agregar el token.
          const wasAdded = await window.ethereum.request({
              method: 'wallet_watchAsset',
              params: {
                  type: 'ERC20',
                  options: {
                      address: DAI_ADDRESS,
                      symbol: DAI_SYMBOL,
                      decimals: DAI_DECIMALS,
                      image: DAI_IMAGE,
                  },
              },
          });

          if (wasAdded) {
              console.log('Token agregado a MetaMask');
          } else {
              console.log('El token ya estaba agregado');
          }
      } catch (e) {
          console.error('Hubo un error al agregar el token a MetaMask', e);
      }
  }

  if (account) {
    const start = "0X...";
    const end = account.slice(38);
  

    return (
<>
      <div
    
        className="ml-3 whitespace-nowrap inline-flex flex-col items-center justify-center px-4 py-1 border border-transparent rounded-md shadow-sm text-base font-medium btn-primary "
      >
        <div className='inline-flex items-center justify-center'>
        <CopyToClipboard
                  text={account}
                  onCopy={() => {
                    alert('Copied Wallet Successfully');
                  }}
                  className="mr-1 h-5 w-5 cursor-pointer"
                >
                  <ClipboardDocumentIcon className="h-4 w-4" />
                </CopyToClipboard>{' '}
        {start}...{end}
        </div>
  
       <button
                    type="button"
                    onClick={addToMetaMask}
                    className="rounded-md bg-gray-100 py-2 px-2 text-xs font-bold text-black mt-1"
                  >
          
                    🦊 ADD CRP Token
                  </button>
      </div>
      
      </>
 
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <ConnectWalletButton scale="sm">
      <Box display={['none', , , 'block']}>
        <Trans>Connect Wallet</Trans>
      </Box>
      <Box display={['block', , , 'none']}>
        <Trans>Connect</Trans>
      </Box>
    </ConnectWalletButton>
  )
}

export default UserMenu
