import { ResetCSS } from '@pancakeswap/uikit'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import FixedSubgraphHealthIndicator from 'components/SubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import type { AppProps } from 'next/app'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import Head from 'next/head'
import { Fragment, useEffect, useState } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { NextPage } from 'next'
import { Blocklist, Updaters } from '..'
import ErrorBoundary from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'
import "./index.css"
import BannerPrice from '../components/Banner'
import useBreakpoints from '../hooks/useBreakpoints'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  useUserAgent()
  usePollCoreFarmData()
  useAccountEventListener()
  useSentryUser()
  return null
}

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { isLg } = useBreakpoints();
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const tabs = [
    { name: 'Swap', href: '/swap', current: true },
    { name: 'Limit', href: '/limit-orders', current: true },
    { name: 'Liquidity', href: '/liquidity', current: true },
  ]

  useEffect(() => {
    setIsSmallScreen(isLg === false);
  }, [isLg]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover" />
        <meta name="description" content="Fast and secure. Discover CRP SWAP, the best decentralized exchange for instant token swaps on the Binance Smart Chain (BSC)." />
        <meta name="theme-color" content="#FF6600" />
        <meta name="author" content="@cryptopeso" />
        <meta name="keywords" content="pay, blockchain, BNB,BSC, BEP20, BEP2, BEP" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CRPSWAP" />
        <meta property="og:url" content="https://crpswap.cryptopeso.co/" />
        <meta property="og:description" content="Fast and secure. Discover CRPSWAP, the best decentralized exchange for instant token swaps on the Binance Smart Chain (BSC)." />
        <meta property="og:image" content="/banner.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="CRPSWAP Banner" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="CRPSWAP" />
        <meta name="twitter:description" content="Fast and secure. Discover CRPSWAP, the best decentralized exchange for instant token swaps on the Binance Smart Chain (BSC)." />
        <meta name="twitter:image" content="/banner.png" />
        <meta name="twitter:image:alt" content="CRPSWAP Banner" />
        <meta name="twitter:site" content="@cryptopeso" />
        <title>CRP SWAP</title>
        <link rel="icon" href="/crp.png" />
      </Head>
      <Providers store={store}>
        <Blocklist>
          <GlobalHooks />
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <Updaters />
            { /* eslint-disable */}

            <div className="bg-black bg-no-repeat bg-center   bg-cover bg-fixed"
                 style={{
                   backgroundImage: `url(${!isSmallScreen ? "/bg.png" : "/bg-mobile.png"})`,
                 }}

            >

            <App {...props} />
            </div>

          </PersistGate>
        </Blocklist>
      </Providers>

    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  return (
    <ProductionErrorBoundary>
      <Menu>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />
    </ProductionErrorBoundary>
  )
}

export default MyApp
